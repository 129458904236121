import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';
export default class SwalPopup {

    constructor($view) {

        this.$view = $view;
        this.Init();
    }

    Init() {
        let self = this;

        this.$view.on("click", '.ConfirmPopup', (e) => {
            self.popupConfirmation(e.currentTarget.dataset)
        });
    }

    // Affiche une popup de confirmation personnalisable. Si l'utilisateur confirme un appel ajax est fait vers l'url donné en paramètre.
    popupConfirmation(data) {
        let type = "POST";
        if (data.type === "GET" || data.type === "POST") {
            type = data.type;
        }

        Swal.fire({
            title: data.titlemodal,
            text: data.textemodal,
            icon: data.iconmodal,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: type,
                    url: data.url,

                    success: function (data) {
                        if (data.resultOk == 1) {
                            if (data.parameterUrl) {
                                window.location.href = window.location.href.replace('#', '') + data.parameterUrl;
                            } else {
                                window.location.reload();
                            }

                        } else if (data.resultOk == 0) {
                            toastr.options = {
                                timeOut: 0,
                                extendedTimeOut: 0
                            };
                            toastr.error(data.resultMessage);
                        }
                    }
                });
            }
        })
    }
}