/*
 * 
 */

export default class OrderableGrid {

    constructor($view) {

        this.view = $view;
        this.url = this.view.data('url');
        this.gridName = this.view.data('grid-name');
        this.Init();
    }

    Init() {
        var self = this;

        $(window).on('OrderableGrid.OnChange', (event, e) => self.OnChange(e));
    }

    OnChange(e) {
        var grid = $("#" + this.gridName).data("kendoGrid"),
            skip = grid.dataSource.skip(),
            oldIndex = e.oldIndex + skip,
            newIndex = e.newIndex + skip,
            data = grid.dataSource.data(),
            dataItem = grid.dataSource.getByUid(e.item.data("uid"));

        grid.dataSource.remove(dataItem);
        grid.dataSource.insert(newIndex, dataItem);

        jQuery.ajax({
            url: this.url,
            type: "POST",
            data: { id: dataItem.Id, oldOrder: oldIndex + 1, newOrder: newIndex + 1 },
            success: function (response) {
                grid.dataSource.read(); //refresh the Grid
            },
            error: function () {
                grid.dataSource.read(); //refresh the Grid
            },
            complete: function () {
                grid.dataSource.cancelChanges();
            }
        });
    }

}