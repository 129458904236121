import Swal from "sweetalert2/dist/sweetalert2";
export default class Catalogue {

    constructor($view) {

        this.$view = $view;

        this.urlExportWin = this.$view.data('url-exportwin');
        this.urlAdminAcheteur = this.$view.data('url-adminacheteur');
        this.Init();
    }

    Init() {
        let self = this;
        this.$view.on("click", '.SelectOption', (e) => {
            self.popupSelectAdminAcheteur(e.currentTarget.dataset)
        });

        this.$view.on("click", '.LaunchExport', (e) => {
            self.launchExport(e.currentTarget.dataset)
        });
    }

    // Affiche une popup avec les admins acheteurs, en cas de validation permet de telecharger l'export WinSystem
    popupSelectAdminAcheteur(data) {
        let self = this;
        $.ajax({
            type: "GET",
            url: self.urlAdminAcheteur,

            success: function (resultAcheteur) {
                var options = {};
                $.map(resultAcheteur,
                    function (o) {
                        options[o.Id] = o.Name;
                    });

                Swal.fire({
                    title: 'Selectionner un client',
                    input: 'select',
                    inputOptions: options,
                    inputPlaceholder: 'Choisissez un acheteur',
                    showCancelButton: true,
                    cancelButtonText: "Annuler",
                    inputValidator: function (value) {
                        return new Promise(function (resolve, reject) {
                            if (value !== '') {
                                resolve();
                            } else {
                                resolve('Vous devez sélectionner un admin acheteur');
                            }
                        });
                    }
                }).then(function (result) {
                    if (result.isConfirmed) {
                        $.ajax({
                            type: "GET",
                            url: self.urlExportWin,
                            data: { id: data.id, idClient: result.value },
                            success: function () {
                            }
                        });
                        toastr.success("L'export Win System à été lancé. Vous pouvez le retrouver dans l'onglet Log des exports Win System");
                    }
                });
            }
        });
    }

    // Lance l'export direct
    launchExport(data) {
        let self = this;

        $.ajax({
            type: "GET",
            url: self.urlExportWin,
            data: { id: data.id },
            success: function () {
            }
        });

        toastr.success("L'export Win System à été lancé. Vous pouvez le retrouver dans l'onglet Log des exports Win System");
    }
}