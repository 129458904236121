import toastr from 'toastr';
import Swal from "sweetalert2/dist/sweetalert2";

export default class clientGrilleTarifaireDragAndDrop {

    constructor($view) {
        this.view = $view;
        this.IsPrivate = this.view.data('is-private');
        this.DraggedInUrl = this.view.data('dragged-in-url');
        this.DraggedOutUrl = this.view.data('dragged-out-url');
        this.ActivateGtUrl = this.view.data('activate-gt-url');
        this.Init();
    }

    Init() {
        var self = this;

        window.DraggedIn = self.window_DraggedIn;
        window.Reload = self.window_Reload;
        window.DraggedOut = self.window_DraggedOut;


        // Afin de trier la listBox de gauche
        $("#search_AvailableClientAcheteursBox").on("input", function (e) {
            var listbox = $("#AvailableClientAcheteurs").data('kendoListBox');
            listbox.dataSource.filter({ field: "ClientName", value: $(e.target).val(), operator: "contains" });
        })

        // Afin de trier la listBox de droite
        $("#search_AuthorizeClientAcheteurs").on("input", function (e) {
            var listbox = $("#AuthorizeClientAcheteurs").data('kendoListBox');
            listbox.dataSource.filter({ field: "ClientName", value: $(e.target).val(), operator: "contains" });
        })

        $(document).on("click", ".js-activate-gt", function () {

            // Convertir this en un objet jQuery
            var $this = $(this);

            // Récupérer l'identifiant de la GT
            var clientId = $this.data("client-id");

            // on affiche une popup de confirmation
            Swal.fire({
                title: "Activer la grille Tarifaire",
                text: "Êtes vous certain de vouloir activer la GrilleTarifaire (et donc créer l'ensemble des grille tarifaire produits) sur le client ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    // une promise afin de gérer l'attente de l'éxecution de lr equète ajax
                    return new Promise(function (resolve, reject) {
                        $.ajax({
                            url: self.ActivateGtUrl,
                            type: 'POST',
                            data: {
                                clientId
                            },
                            success: function (data) {
                                if (!data.success) {
                                    toastr.error(data.msg);
                                } else {
                                    // Traitement en cas de succès
                                    toastr.success(data.msg);
                                }
                                resolve();
                            },
                            error: function (data) {
                                reject();
                            },
                        });
                    })
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    //en cas de succès
                    toastr.success("La grille Tarifaire a été activée !");

                    self.RefreshListBox();
                }
            }).catch((erreur) => {
                //en cas d'erreur
                Swal.close();
                toastr.error("Une erreur est survenue");
            })
        });

        // bouton pour supprimer le filtre
        $(".flush-filter").on("click", function (e) {
            // on réupère l'input et on flush
            $(this).prev("input").val('');
            $(this).prev("input").trigger('input');
        })

    }

    // Quand on ajoute un élément dans la liste principale
    window_DraggedIn(e) {
        e.preventDefault();

        var elem = e.dataItems[0];

        $.ajax({
            url: $("#ClientGrilleTarifaireDragAndDrop-component").data("dragged-in-url"),
            data: {
                clientId: elem.ClientId,
                distributeurId: elem.DistributeurId
            },
            type: 'POST',
            success: function (data) {
                if (!data.success) {
                    toastr.error(data.msg);
                } else {
                    // Traitement en cas de succès
                    toastr.success(data.msg);
                }
            },
            error: function (xhr, status, error) {
                toastr.error("Une erreur inattendue s'est produite.");
            }
        }).always(function () {
            // On refresh la liste
            $("#ClientGrilleTarifaireDragAndDrop-component").data('component').RefreshListBox();
        });
    }

    // Quand on sort un élément de la liste principale
    window_DraggedOut(e) {
        e.preventDefault();

        var elem = e.dataItems[0];

        $.ajax({
            url: $("#ClientGrilleTarifaireDragAndDrop-component").data("dragged-out-url"),
            data: {
                clientId: elem.ClientId,
                distributeurId: elem.DistributeurId
            },
            type: 'POST',
            success: function (data) {

                console.log(data);

                if (!data.success) {
                    toastr.error(data.msg);
                } else {
                    // Traitement en cas de succès
                    toastr.success(data.msg);
                }
            },
            error: function (xhr, status, error) {
                toastr.error("Une erreur inattendue s'est produite.");
            }
        }).always(function () {
            // On refresh la liste
            $("#ClientGrilleTarifaireDragAndDrop-component").data('component').RefreshListBox();
        });
    }

    // afin de forcer le Sorting pour fake la TreeList
    RefreshListBox() {

        console.log("RefreshListBox");

        var ListBox = $("#AvailableClientAcheteurs").data("kendoListBox");
        ListBox.dataSource.sort({ field: "SortingId", dir: "asc" });
        ListBox.dataSource.read();
        ListBox.refresh();

        ListBox = $("#AuthorizeClientAcheteurs").data("kendoListBox");
        ListBox.dataSource.sort({ field: "SortingId", dir: "asc" });
        ListBox.dataSource.read();
        ListBox.refresh();
    }

    // Pour simplement reload
    window_Reload(e) {
        e.preventDefault();
        $("#ClientGrilleTarifaireDragAndDrop-component").data('component').RefreshListBox();
    }

}