//import toastr from 'toastr';

class OrderableGrid {
    constructor() {
        this.Init();
        this.noHint = $.noop;
    }

    Init() {
        console.log('grid.js');
    }

    placeholder(element) {
        return element.clone().addClass("k-state-hover").css("opacity", 0.65);
    }

    onOrderChange(e) {
        console.log(e);
        try {
            var divGrid = $(e.item[0]).closest(".grid-orderable");
            var grid = $(divGrid).data("kendoGrid");
            var isTreeList = false;

            // Si grid est undefined c'est qu'on est potentiellement sur une Treelist
            if (!grid) {
                grid = $(divGrid).data("kendoTreeList");
                isTreeList = true;
            }

            var skip = grid.dataSource.skip();
            if (typeof (skip) == "undefined") skip = 0;

            var oldIndex = e.oldIndex + skip,
                newIndex = e.newIndex + skip,
                data = grid.dataSource.data(),
                dataItem = grid.dataSource.getByUid(e.item.data("uid"));
            grid.dataSource.remove(dataItem);
            grid.dataSource.insert(newIndex, dataItem);

            var datas = JSON.stringify({ Vm: dataItem, oldOrder: oldIndex + 1, newOrder: newIndex + 1 });

            jQuery.ajax({
                url: $(divGrid).data("orderable-change-url"),
                type: "POST",
                contentType: "application/json; charset=utf-8",
                processData: true,
                data: datas,
                success: function (response) {
                    grid.dataSource.read(); //refresh the Grid
                    if (isTreeList) {
                        // Pour refresh une treeList on est obligé de procéder différement
                        grid.refresh();
                        grid.setDataSource(grid.options.dataSource);
                    }
                    toastr.success("L'ordre a été mis à jour !");
                },
                error: function () {
                    toastr.error("Une erreur est survenue");
                },
                complete: function () {
                    grid.dataSource.cancelChanges();
                }
            });
        }
        catch (err) {
            //Onlimite les erreurs qui peuvent subvenir
            toastr.error("Une erreur est survenue");
        }

    }
}

module.exports = OrderableGrid;