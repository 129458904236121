/*
 *
 */

import toastr from 'toastr';
export default class Alert {

    constructor($view) {

        this.$view = $view;
        this.$alert = this.$view.find('#AlertId');
        this.Init();
    }

    Init() {
        let self = this;
        if (this.$alert.text().toLowerCase() == "false") {
            toastr.options = {
                timeOut: 0,
                extendedTimeOut: 0
            };
            toastr.error(this.$alert.data().message);
        } else if (this.$alert.text().toLowerCase() == "true") {
            toastr.options = {
                timeOut: 0,
                extendedTimeOut: 0
            };
            toastr.success(this.$alert.data().message);
        }


    }
}