import Swal from "sweetalert2/dist/sweetalert2";
import toastr, { error } from 'toastr';

export default class PageCatalogue {

    constructor($view) {
        this.view = $view;

        this.DeleteAllProduitUrl = this.view.data('deleteallproduiturl');

        this.ConfirmDeleteTitle = this.view.data('swal-title');
        this.ConfirmDeleteOverrideMsg = this.view.data('swal-msg');

        this.UpdateMsg = this.view.data('update-msg');
        this.IndexUrl = this.view.data('index-url');

        this.init();
    }

    init() {

        let self = this;
        let jobId = null;
        $(".delete-produit-catalogue", self.view).on('click', function () {

            // on affiche une popup de confirmation
            Swal.fire({
                title: self.ConfirmDeleteTitle,
                text: self.ConfirmDeleteOverrideMsg + " ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    // une promise afin de gérer l'attente de l'éxecution de lr equète ajax
                    return new Promise(function (resolve, reject) {
                        // here should be AJAX request
                        $.ajax({
                            url: self.DeleteAllProduitUrl,
                            type: 'POST',
                            success: function (data) {

                                jobId = data.jobId;
                                resolve();
                            },
                            error: function(data) {
                                reject();
                            },
                        });
                    })
                }
                }).then((result) => {
                    if (result.isConfirmed) {
                        //en cas de succès
                        toastr.success(self.UpdateMsg);

                        if (jobId) {
                            // il faut rediriger vers la liste des catalogue
                            window.open("/hangfire/jobs/details/" + jobId, '_blank').focus();
                        } 
                        // il faut rediriger vers la liste des catalogue
                        window.location.href = self.IndexUrl;
                        
                    }
                }).catch((erreur) => {
                    //en cas d'erreur
                    Swal.close();
                    toastr.error("Une erreur est survenue");
                })
        });
    }

    //Permet de refresh la grid
    RefreshGrid() {
        let self = this;

        var grid = $("#produitsCatalogue").data("kendoGrid");
        grid.dataSource.read();
        grid.refresh();
    }
}