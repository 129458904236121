import Swal from "sweetalert2/dist/sweetalert2";
import toastr, { error } from 'toastr';

export default class pageExportCatalogue {

    constructor($view) {
        this.view = $view;

        this.ConfirmDeleteTitle = this.view.data('swal-title');
        this.ConfirmDeleteOverrideMsg = this.view.data('swal-msg');
        this.init();
    }

    init() {

        let self = this;

        $(".export-winsystem-catalogue", self.view).on('click', function () {

            // on affiche une popup de confirmation
            Swal.fire({
                title: self.ConfirmDeleteTitle,
                text: self.ConfirmDeleteOverrideMsg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                //preConfirm: function () {
                //    // une promise afin de gérer l'attente de l'éxecution de la requète ajax
                //    self.submitForm();
                //}
                }).then((result) => {
                    if (result.isConfirmed) {
                        document.getElementById('Form_ExportWinSystem').submit();
                    }
                }).catch((erreur) => {
                    //en cas d'erreur
                    Swal.close();
                    toastr.error("Une erreur est survenue");
                })
        });
    }
}