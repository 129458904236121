//import toastr from 'toastr';

class AppError {
    constructor() {
        this.Init();
    }

    Init() {
        console.log('AppError.js');
    }

    // Afin de logger les erreurs dans les grids
    // Possibilité d'afficher des messages personnalisé en utilisant '# Mon custom message #'
    // Sinon renvoie Une erreur est survenue
    GridErrorHandler(e) {

        // On affiche que la première partie du message entre #
        var potentielErrorMessage = e.xhr.responseText.split('#');
        
        toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
        };

        // Pour récupérer le contenu entre les #
        if (potentielErrorMessage.length >= 3) { // Vérifie qu'il y a au moins deux occurrences de #
            let errorMsg = potentielErrorMessage[1];
            toastr.error(errorMsg);
        } else {
            toastr.error("Une erreur est survenue");
        }
    }
}

module.exports = AppError;