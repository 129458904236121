/*
 * Composant js 
 * Utilisé sur la vue de creation et d'edition d'un client
 * Fonctionnalité : 
 * - Affiche et cache les liens de pre remplissage de l'adresse
 * - Call ajax pour pre remplir l'adresse
 */

export default class ClientAdresse {

    constructor($view) {

        this.$view = $view;
        this.$preFillAdresseDistributeur = this.$view.find('#PreFillAdresseDistributeur');
        this.$preFillAdresseDistributeurLi = this.$view.find('#PreFillAdresseDistributeurLi');
        this.$preFillAdresseClientParent = this.$view.find('#PreFillAdresseClientParent');
        this.$preFillAdresseClientParentLi = this.$view.find('#PreFillAdresseClientParentLi');
        this.$adressePartial = this.$view.find('#AdressePartial');
        this.$allDistributeurs = this.$view.find('#DistributeurId');
        this.$allClientParent = this.$view.find('#ClientParentID');
        this.urlPreFill = this.$view.data('url');
        this.Init();
    }

    Init() {
        let self = this;

        // on appelle les methodes pour cacher les liens si y a besoin
        self.visibilityPreFillAdresseDistributeur();
        self.visibilityPreFillAdresseClientParent();

        // si on change l'element dans la liste des distributeurs on reverifie qu'il ne faut pas cacher le lien de prefill si l'element choisit est l'element vide
        this.$view.on("change", '#DistributeurId', (e) => {
            self.visibilityPreFillAdresseDistributeur()
        });

        // si on change l'element dans la liste des client parents on reverifie qu'il ne faut pas cacher le lien de prefill si l'element choisit est l'element vide
        this.$view.on("change", '#ClientParentID', (e) => {
            self.visibilityPreFillAdresseClientParent()
        });

        // au clic sur pre remplir avec l'adresse fabricant
        this.$preFillAdresseDistributeur.on("click", (e) => {
            let distrib = self.$allDistributeurs.find("option:selected").attr("selected", true);
            self.callPreFillAdresse("distributeur", distrib.val())
        });

        // au clic sur pre remplir avec l'adresse client parent
        this.$preFillAdresseClientParent.on("click", (e) => {
            let clientParent = self.$allClientParent.find("option:selected").attr("selected", true);
            self.callPreFillAdresse("clientParent", clientParent.val())
        });
    }

    // call ajax pour remplir l'adresse du client
    callPreFillAdresse(isParentCall, idCall) {
        let self = this;
        $.ajax({
            type: 'POST',
            url: self.urlPreFill,
            data: {
                adresseFrom: isParentCall,
                id: idCall
            },

            success: function (data) {
                if (data.errorMessage) {
                    toastr.options = {
                        timeOut: 0,
                        extendedTimeOut: 0
                    };
                    toastr.error(data.errorMessage);
                } else {
                    self.$adressePartial.html(data)
                }
            },
            error: function (data) {

            }
        });
    }

    // cache ou montre le lien de pre remplissage pour l'adresse distrib
    visibilityPreFillAdresseDistributeur() {
        let self = this;
        let distrib = self.$allDistributeurs.find("option:selected").attr("selected", true).val();
        if (distrib == null || distrib == '00000000-0000-0000-0000-000000000000') {
            this.$preFillAdresseDistributeurLi.hide();
        } else {
            this.$preFillAdresseDistributeurLi.show();
        }
    }

    // cache ou montre le lien de pre remplissage pour l'adresse client parent
    visibilityPreFillAdresseClientParent() {
        let self = this;
        let clientParent = self.$allClientParent.find("option:selected").attr("selected", true).val();
        if (clientParent == null || clientParent == '00000000-0000-0000-0000-000000000000') {
            this.$preFillAdresseClientParentLi.hide();
        } else {
            this.$preFillAdresseClientParentLi.show();
        }
    }
}