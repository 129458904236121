
/*
 *
 */

export default class ClientTarif {

    constructor($view) {

        this.$view = $view;
        this.$allCatalogues = this.$view.find('#CatalogueId');
        this.$allDistributeurs = this.$view.find('#DistributeurId');
        this.$allGrillesTarifaires = this.$view.find('#GrilleTarifaireId');
        this.Init();
    }

    Init() {
        let self = this;

        let optionDefault = self.$allDistributeurs.find("option:first").attr("selected", true);

        self.callChangeCatalogues(self, optionDefault.val());

        // Au changement du distributeur on va charger la liste de catalogues avec l'id du distributeur
        this.$view.on("change", '#DistributeurId', (e) => {
            self.callChangeCatalogues(self, e.currentTarget.value);

        });

        // Au changement de catalogue on charge la nouvelle grille tarifaire lie au catalogue
        this.$view.on("change", '#CatalogueId', (e) => {
            self.callChangeGrillesTarifaires(self, e.currentTarget.value);
        });
    }

    callChangeCatalogues(self, idDis) {
        $.ajax({
            type: 'POST',
            url: '/ClientTarif/ChangeAllCatalogues',
            data: {
                idDistributeur: idDis
            },

            success: function (data) {
                // On vide la liste de catalogues pour pouvoir ajouter que ceux qu'on veut
                self.$allCatalogues.empty();

                data.allCatalogues.forEach(function (item) {
                    self.$allCatalogues.append($('<option>', {
                        value: item.Id,
                        text: item.Nom
                    }));

                });

                // On charge la liste de grille tarifaire avec le nouveau catalogues
                let grilTarifDefaut = self.$allCatalogues.find("option:first").attr("selected", true);
                self.callChangeGrillesTarifaires(self, grilTarifDefaut.val());

            }
        });
    }

    callChangeGrillesTarifaires(self, idCat) {
        $.ajax({
            type: 'POST',
            url: '/ClientTarif/ChangeAllGrillesTarifaires',
            data: {
                idCatalogue: idCat
            },

            success: function (data) {
                // On vide la liste de catalogues pour pouvoir ajouter que ceux qu'on veut
                self.$allGrillesTarifaires.empty();

                data.allGrillesTarifaires.forEach(function (item) {
                    self.$allGrillesTarifaires.append($('<option>', {
                        value: item.Id,
                        text: item.Libelle
                    }));

                });

            }
        });
    }

}