export default class ImportList {

    constructor($view) {
        this.view = $view;
        this.init();
    }

    init() {
        let self = this;

   
        if (self.view.find('#SuccessNotificationContent').length > 0) {
            toastr.options = {
                timeOut: 0,
                extendedTimeOut: 0
            };
            toastr.success(self.view.find('#SuccessNotificationContent').html());
        }

    }
}