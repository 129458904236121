import toastr from 'toastr';

export default class ClientDistributeurDragAndDrop {

    constructor($view) {
        this.view = $view;
        this.IsPrivate = this.view.data('is-private');
        this.DraggedInUrl = this.view.data('dragged-in-url');
        this.DraggedOutUrl = this.view.data('dragged-out-url');
        this.UpdateMsg = this.view.data('update-msg');
        this.SuccessOperationMsg = this.view.data('success-msg');
        this.Init();
    }

    Init() {
        var self = this;

        window.DraggedIn = self.window_DraggedIn;
        window.Reload = self.window_Reload;
        window.DraggedOut = self.window_DraggedOut;


        // Afin de trier la listBox de gauche
        $("#search_AvailableClientAcheteursBox").on("input", function (e) {
            var listbox = $("#AvailableClientAcheteurs").data('kendoListBox');
            listbox.dataSource.filter({ field: "ClientName", value: $(e.target).val(), operator: "contains" });
        })

        // Afin de trier la listBox de droite
        $("#search_AuthorizeClientAcheteurs").on("input", function (e) {
            var listbox = $("#AuthorizeClientAcheteurs").data('kendoListBox');
            listbox.dataSource.filter({ field: "ClientName", value: $(e.target).val(), operator: "contains" });
        })

        // bouton pour supprimer le filtre
        $(".flush-filter").on("click", function (e) {
            // on réupère l'input et on flush
            $(this).prev("input").val('');
            $(this).prev("input").trigger('input');
        })

    }

    // Quand on ajoute un élément dans la liste principale
    window_DraggedIn(e) {
        e.preventDefault();

        var elem = e.dataItems[0];

        $.ajax({
            url: $("#ClientDistributeurDragAndDrop-component").data("dragged-in-url"),
            data: {
                clientId: elem.ClientId,
                distributeurId: elem.DistributeurId
            },
            type: 'POST',
            success: function (data) {
                toastr.success($("#ClientDistributeurDragAndDrop-component").data("update-msg") + " " + $("#ClientDistributeurDragAndDrop-component").data("success-msg"));
                $("#DroitsDistributeur-component").data('component').RefreshClientDistributeurGrid();
                $("#ClientDistributeurDragAndDrop-component").data('component').RefreshListBox();
            }
        });
    }

    // afin de forcer le Sorting pour fake la TreeList
    RefreshListBox() {
        var ListBox = $("#AvailableClientAcheteurs").data("kendoListBox");
        ListBox.dataSource.sort({ field: "SortingId", dir: "asc" });
        ListBox.dataSource.read();
        ListBox.refresh();

        ListBox = $("#AuthorizeClientAcheteurs").data("kendoListBox");
        ListBox.dataSource.sort({ field: "SortingId", dir: "asc" });
        ListBox.dataSource.read();
        ListBox.refresh();
    }

    // Pour simplement reload
    window_Reload(e) {
        e.preventDefault();
        $("#ClientDistributeurDragAndDrop-component").data('component').RefreshListBox();
    }

    // Quand on sort un élément de la liste principale
    window_DraggedOut(e) {
        e.preventDefault();

        var elem = e.dataItems[0];

        $.ajax({
            url: $("#ClientDistributeurDragAndDrop-component").data("dragged-out-url"),
            data: {
                clientId: elem.ClientId,
                distributeurId: elem.DistributeurId
            },
            type: 'POST',
            success: function (data) {
                toastr.success($("#ClientDistributeurDragAndDrop-component").data("update-msg") + " " + $("#ClientDistributeurDragAndDrop-component").data("success-msg"));
                // On refresh la treeList 
                $("#DroitsDistributeur-component").data('component').RefreshClientDistributeurGrid();
                $("#ClientDistributeurDragAndDrop-component").data('component').RefreshListBox();
            }
        });
    }

}