/*
 *
 */

export default class GrillesTarifaires {

    constructor($view) {

        this.$view = $view;
        this.$allCatalogues = this.$view.find('#CatalogueId');
        this.$allDistributeurs = this.$view.find('#DistributeurId');
        this.urlExportWin = this.$view.data('url-exportwin');
        this.Init();
    }

    Init() {
        let self = this;

        let optionDefault = self.$allDistributeurs.find("option:selected").attr("selected", true);

        // Si jamais on a une option de selectionne dans la liste des catalagues c'est qu'on est en edition de grilles tarifaires
        // et donc on ne fait pas le premier call pour remplir cette liste car elle l'est deja
        let cataloguesSelected = self.$allCatalogues.find("option:selected").attr("selected", true);
        if (cataloguesSelected.length == 0) {
            self.callChangeCatalogues(self, optionDefault.val());
        }
       
        this.$view.on("change", '#DistributeurId', (e) => {
            self.callChangeCatalogues(self, e.currentTarget.value) 
        });

        this.$view.on("click", '.LaunchExport', (e) => {
            console.log('ici');
            self.launchExport(e.currentTarget.dataset)
        });
    }

    // Lance l'export direct
    launchExport(data) {
        let self = this;

        $.ajax({
            type: "GET",
            url: self.urlExportWin,
            data: {
                id: data.id,
                idClient: data.clientid
            },
            success: function (data) {
                
                var jobId = data.jobId;
                if (jobId) {

                    // il faut rediriger vers la liste des catalogue
                    window.open("/hangfire/jobs/details/" + jobId, '_blank').focus();
                } 

                // il faut rediriger vers la liste des catalogue
                window.location.href = "/GrillesTarifaires/ExportLog";

            }
        });

        toastr.success("L'export Win System à été lancé. Vous pouvez le retrouver dans l'onglet Log des exports Win System");
    }

    callChangeCatalogues(self, idDis) {
        $.ajax({
            type: 'POST',
            url: '/GrillesTarifaires/ChangeAllCatalogues',
            data: {
                idDistributeur: idDis
            },

            success: function (data) {
                // On vide la liste de catalogues pour pouvoir ajouter que ceux qu'on veut
                self.$allCatalogues.empty();

                data.allCatalogues.forEach(function (item) {
                    self.$allCatalogues.append($('<option>', {
                        value: item.Id,
                        text: item.Nom
                    }));

                });

            }
        });
    }
}