/*
 *
 */

export default class ClientCatalogue {

    constructor($view) {

        this.$view = $view;
        this.$allCatalogues = this.$view.find('#CatalogueId');
        this.$allDistributeurs = this.$view.find('#DistributeurId');
        this.Init();
    }

    Init() {
        let self = this;

        let optionDefault = self.$allDistributeurs.find("option:selected").attr("selected", true);

        self.callChangeCatalogues(self, optionDefault.val());

        this.$view.on("change", '#DistributeurId', (e) => {
            self.callChangeCatalogues(self, e.currentTarget.value)
        });
    }

    callChangeCatalogues(self, idDis) {
        $.ajax({
            type: 'POST',
            url: '/Clients/ChangeAllCatalogues',
            data: {
                idDistributeur: idDis
            },

            success: function (data) {
                // On vide la liste de catalogues pour pouvoir ajouter que ceux qu'on veut
                self.$allCatalogues.empty();

                data.allCatalogues.forEach(function (item) {
                    self.$allCatalogues.append($('<option>', {
                        value: item.Id,
                        text: item.Nom
                    }));

                });

            }
        });
    }
}