/*
 *
 */

export default class ProduitsSurcharge {

    constructor($view) {

        this.$view = $view;
        this.$surchargeLibelleDiv = this.$view.find('#SurchargeLibelleDiv');
        this.$surchargeLibelleLinkPlus = this.$view.find('#SurchargeLibelleLinkPlus');
        this.$surchargeLibelleLinkMinus = this.$view.find('#SurchargeLibelleLinkMinus');
        this.$surchargeLibelleTextBox = this.$view.find('#ClientProduitSurcharge_Libelle');

        this.$surchargeDescriptionDiv = this.$view.find('#SurchargeDescriptionDiv');
        this.$surchargeDescriptionLinkPlus = this.$view.find('#SurchargeDescriptionLinkPlus');
        this.$surchargeDescriptionLinkMinus = this.$view.find('#SurchargeDescriptionLinkMinus');
        this.$surchargeDescriptionTextBox = this.$view.find('#ClientProduitSurcharge_Description');

        this.$surchargeReferenceDiv = this.$view.find('#SurchargeReferenceDiv');
        this.$surchargeReferenceLinkPlus = this.$view.find('#SurchargeReferenceLinkPlus');
        this.$surchargeReferenceLinkMinus = this.$view.find('#SurchargeReferenceLinkMinus');
        this.$surchargeReferenceTextBox = this.$view.find('#ClientProduitSurcharge_ReferenceFabriquant');

        this.$surchargePrixConseilleDiv = this.$view.find('#SurchargePrixConseilleDiv');
        this.$surchargePrixConseilleLinkPlus = this.$view.find('#SurchargePrixConseilleLinkPlus');
        this.$surchargePrixConseilleLinkMinus = this.$view.find('#SurchargePrixConseilleLinkMinus');
        this.$surchargePrixConseilleTextBox = this.$view.find('#ClientProduitSurcharge_PrixConseilleString');

        this.Init();
    }

    Init() {
        let self = this;

        // Clic sur le bouton plus pour le libelle
        this.$surchargeLibelleLinkPlus.on("click", (e) => {
            self.showSurcharge(self.$surchargeLibelleDiv, self.$surchargeLibelleLinkPlus, "libelle");

        });

        // Clic sur le bouton moins pour le libelle
        this.$surchargeLibelleLinkMinus.on("click", (e) => {
            self.hideSurcharge(self.$surchargeLibelleDiv, self.$surchargeLibelleLinkPlus, self.$surchargeLibelleTextBox, "libelle");
        });

        // Clic sur le bouton plus pour la description
        this.$surchargeDescriptionLinkPlus.on("click", (e) => {
            self.showSurcharge(self.$surchargeDescriptionDiv, self.$surchargeDescriptionLinkPlus, "description");
        });
       
        // Clic sur le bouton moins pour la description
        this.$surchargeDescriptionLinkMinus.on("click", (e) => {
            self.hideSurcharge(self.$surchargeDescriptionDiv, self.$surchargeDescriptionLinkPlus, self.$surchargeDescriptionTextBox, "description");
        });


        // Clic sur le bouton plus pour la description
        this.$surchargeReferenceLinkPlus.on("click", (e) => {
            self.showSurcharge(self.$surchargeReferenceDiv, self.$surchargeReferenceLinkPlus, "reference");
        });

        // Clic sur le bouton moins pour la description
        this.$surchargeReferenceLinkMinus.on("click", (e) => {
            self.hideSurcharge(self.$surchargeReferenceDiv, self.$surchargeReferenceLinkPlus, self.$surchargeReferenceTextBox, "reference");
        });

        // Clic sur le bouton plus pour le prix conseillé
        this.$surchargePrixConseilleLinkPlus.on("click", (e) => {
            self.showSurcharge(self.$surchargePrixConseilleDiv, self.$surchargePrixConseilleLinkPlus, "PrixConseilleString");
        });

        // Clic sur le bouton moins pour le prix conseillé
        this.$surchargePrixConseilleLinkMinus.on("click", (e) => {
            self.hideSurcharge(self.$surchargePrixConseilleDiv, self.$surchargePrixConseilleLinkPlus, self.$surchargePrixConseilleTextBox, "PrixConseilleString");
        });
    }

    showSurcharge(div, link, field) {
        div.show();
        link.hide();
    }

    hideSurcharge(div, link, textbox, field) {
        div.hide();
        link.show();
        textbox.val(""); 
    }
}