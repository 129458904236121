import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class grilleTarifaireAcheteurTreeList {
    constructor($view) {
        this.$view = $view;
        this.SuccessOperationMsg = this.$view.data('success-msg');
        this.UpdateMsg = this.$view.data('update-msg');
        this.CreateMsg = this.$view.data('create-msg');
        this.DeleteMsg = this.$view.data('delete-msg');
        this.ConfirmDeleteTitle = this.$view.data('swal-title');
        this.ConfirmDeleteOverrideMsg = this.$view.data('swal-msg');
        this.UpdateCoefValueUrl = this.$view.data('updatecoefvalueurl');
        this.SetMultipleProduitGrilleTarifaireUrl = this.$view.data('setmultipleproduitgrilletarifaireurl');

        this.Init();
    }

    Init() {
        let self = this;

        // On s'abonne au click du bouton de maj des coef de la grille
        $("#override-grilletarifaire").on("click", function () {
            self.OverrideGrilleTarifaire();
        });

        $("#expand-treelist").on("click", function () {
            self.ExpandTreelist(self);
        });

        $(function () {
            // on delay d'une seconde sinon le bouton n'existe pas encore
            setTimeout(
                function () {
                    // On s'abonne au click du bouton de maj des coef via les checkbo de la treelist
                    $("#set-values").on("click", function () {
                        self.SetMultipleProduitGrilleTarifaire(self);
                    });
                }, 1000);
        });


        // Afin d'exposer sur la page les méthodes JS du composant (pour la grille Kendo)
        window.onDataBound = self.window_onDataBound;
        window.onEdit = self.window_onEdit;
        window.onCancel = self.window_onCancel;
        window.setVisible = self.window_setVisible;
        window.onRequestEnd = self.window_onRequestEnd;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;

        // afin d'utiliser les messages passés en ressource dans un contexte hors composant (sans le this)
        window.SuccessOperationMsg = this.SuccessOperationMsg
        window.UpdateMsg = this.UpdateMsg;
        window.CreateMsg = this.CreateMsg;
        window.DeleteMsg = this.DeleteMsg;
        window.ConfirmDeleteTitle = this.ConfirmDeleteTitle;
        window.ConfirmDeleteOverrideMsg = this.ConfirmDeleteOverrideMsg;
    }

    ExpandTreelist(self) {

        var treeList = $("#TreeList_GrilleTarifaire").data("kendoTreeList");

        // En fonction de l'état actuel on va collapse ou expand
        treeList.options.dataSource.schema.model.expanded = !treeList.options.dataSource.schema.model.expanded
        treeList.setDataSource(treeList.options.dataSource);

        // on change la visibilité de l'icone
        if (!treeList.options.dataSource.schema.model.expanded) {
            $("#expand-treelist .fa-toggle-off").show();
            $("#expand-treelist .fa-toggle-on").hide();
        } else {
            $("#expand-treelist .fa-toggle-off").hide();
            $("#expand-treelist .fa-toggle-on").show();
        }

        // on force la sélection sur le "Tous" des filtres des déclianaison car sinon c'est pas cohérent
        $("input:radio[name=inlineRadioOptions][label=Tous]").prop("checked", true);
    }

    // afin de modifier les valeur de coef par défaut de la grille tarifaire
    OverrideGrilleTarifaire() {
        let self = this;

        // on récupère les valeurs des coeff
        var coefVente = $("#OverridedCoefficientDeVenteParDefaut", self.$view).val();

        // Afin de modifier le count des produits concerné sur la page
        $.ajax({
            url: self.UpdateCoefValueUrl,
            data: {
                coefVente
            },
            type: 'POST',
            success: function (data) {
                toastr.success(UpdateMsg + " " + SuccessOperationMsg);

                // il faut recharger la grille
                self.RefreshGrid();
            }
        });
    }

    // Pour mettre à jour en masse les valeur des coeff via les checkbox
    SetMultipleProduitGrilleTarifaire(self) {

        var selectedIds = [],
            treeList = $("#TreeList_GrilleTarifaire").data("kendoTreeList");

        // On récupère les lignes sélectionné via la checkbox
        var row = treeList.select();

        // On boucle pour compléter le tableau d'Ids
        for (var i = 0; i < row.length; i++) {
            var data = treeList.dataItem(row[i]);
            selectedIds.push(data.Id);
        }

        if (selectedIds.length < 1) {
            toastr.error("Veuillez sélectionner au moins une ligne à modifier !");
        } else {
            // On va demander via une popup la valeur 
            Swal.fire({
                title: 'Coefficient de vente à appliquer',
                input: 'text',
                inputAttributes: {
                    min: 0
                },
                inputValidator: (value) => {
                    if (isNaN(value)) {
                        return 'Veuillez entrer un nombre décimal';
                    }
                },
                showCancelButton: true,
                confirmButtonText: 'Ok',
                showLoaderOnConfirm: true,
                preConfirm: function (value) {
                    // une promise afin de gérer l'attente de l'éxecution de lr equète ajax
                    return new Promise(function (resolve, reject) {

                    var selectedIds = [],
                        treeList = $("#TreeList_GrilleTarifaire").data("kendoTreeList");

                    // On récupère les lignes sélectionné via la checkbox
                    var row = treeList.select();

                    // On boucle pour compléter le tableau d'Ids
                    for (var i = 0; i < row.length; i++) {
                        var data = treeList.dataItem(row[i]);
                        selectedIds.push(data.Id);
                    }

                        // here should be AJAX request
                        $.ajax({
                            url: self.SetMultipleProduitGrilleTarifaireUrl,
                            type: 'POST',
                            data: {
                                // replace sinon il reconnait pas le decimal dans l'action MVC
                                coefVenteValue: value.replace(".", ","),
                                produitIds: selectedIds
                            },
                            success: function (data) {
                                resolve();
                            },
                            error: function (data) {
                                reject();
                            },
                        });
                    })
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    //en cas de succès
                    toastr.success(self.UpdateMsg);
                    // il faut recharger la grille
                    self.RefreshGrid();
                }
            }).catch((erreur) => {
                //en cas d'erreur
                Swal.close();
                toastr.error("Une erreur est survenue");
            });
        }
    }

    //Permet de refresh la grid
    RefreshGrid() {
        let self = this;

        var treeList = $("#TreeList_GrilleTarifaire").data("kendoTreeList");

        treeList.dataSource.read();

        //var options = treeList.getOptions();

        //treeList.setOptions(options);

        //treeList.dataSource.sync();
        treeList.refresh();

        treeList.setDataSource(treeList.options.dataSource);

        // on force la sélection sur le "Tous" des filtres des déclianaison car sinon c'est pas cohérent
        $("input:radio[name=inlineRadioOptions][label=Tous]").prop("checked", true);
    }

    // Sur l'event DataBound de la grille
    window_onDataBound() {
        //Afin de différer la coleur de la ligne en fonction de si il y a surcharge ou pas
        var grid = $("#TreeList_GrilleTarifaire").data("kendoTreeList");
        var data = grid.dataSource.data();
        $.each(data, function (i, row) {
            if (row.OverrideOrNotBool) {
                $('tbody tr[data-uid="' + row.uid + '"]').css("background-color", "#00000008");

                // on va masquer le bouton supprimer
                $('tbody tr[data-uid="' + row.uid + '"] .k-button.k-button-icontext.k-grid-delete').show();
            } else {
                $('tbody tr[data-uid="' + row.uid + '"]').css("background-color", "#fbfbfb");

                // on va masquer le bouton supprimer
                $('tbody tr[data-uid="' + row.uid + '"] .k-button.k-button-icontext.k-grid-delete').hide();

            }
        })

        // Afin de modifier le count des produits concerné sur la page
        $.ajax({
            url: $("#grilleTarifaireAcheteurTreeList-component").data("setcountnbproduiturl"),
            type: 'POST',
            success: function (data) {
                $("#NbProduitConcerne").data("kendoNumericTextBox").value(data.NbProduitConcerne);
                $("#NbProduitSurcharge").data("kendoNumericTextBox").value(data.NbProduitSurcharge);
            }
        });
    }

    // Sur l'event DataBound de la grille
    window_onEdit(e) {
        // Pour afficher le bouton cancel car il est parfoit masquer à cause du dataBound
        $('.k-button.k-button-icontext.k-grid-cancel', e.container).show();
    }

    window_onCancel(e) {
        // Pour masquer le bouton cancel car il est parfoit masquer à cause du dataBound
        if (!e.model.OverrideOrNotBool) {
            $('.k-button.k-button-icontext.k-grid-cancel', e.container).hide();
        } else {
            $('.k-button.k-button-icontext.k-grid-cancel', e.container).show();
        }
    }

    window_setVisible(dataItem) {
        // return true or false dependent on the dataItem field value
        return dataItem.OverrideOrNotBool;
    }

    // Afin de forcer le readf de la grille après une suppression ou un update
    window_onRequestEnd(e) {
        if (e.type === "destroy") {
            e.sender.read();
            toastr.success(DeleteMsg + " " + SuccessOperationMsg);
        } else if (e.type === "create") {
            //e.sender.read();
            $("#grilleTarifaireAcheteurTreeList-component").data("component").RefreshGrid();
            toastr.success(CreateMsg + " " + SuccessOperationMsg);
        }
        else if (e.type === "update") {
            //e.sender.read();
            $("#grilleTarifaireAcheteurTreeList-component").data("component").RefreshGrid();
            toastr.success(UpdateMsg + " " + SuccessOperationMsg);
        }
    }

    window_showDeleteConfirmation(e) {
        // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
        e.preventDefault();

        var grid = $("#TreeList_GrilleTarifaire").data("kendoTreeList");
        var modelToDelete = grid.dataItem($(e.target).parents('tr'));

        Swal.fire({
            title: ConfirmDeleteTitle,
            text: ConfirmDeleteOverrideMsg + " " + modelToDelete.IdentifiantExterne + " ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {

                // Afin de modifier le count des produits concerné sur la page
                $.ajax({
                    url: $("#grilleTarifaireAcheteurTreeList-component").data("deleteproduitgrilletarifaireurl"),
                    type: 'POST',
                    data: {
                        grilleTarifaireProduitId: modelToDelete.GrilleTarifaireProduitId
                    },
                    success: function (data) {
                        $("#grilleTarifaireAcheteurTreeList-component").data("component").RefreshGrid();
                        toastr.success(DeleteMsg + " " + SuccessOperationMsg);
                    }
                });
            }
        })
    }
}