import toastr from 'toastr';
import Swal from "sweetalert2/dist/sweetalert2";


export default class DroitsDistributeur {

    constructor($view) {
        this.view = $view;
        this.ChangeIsprivateUrl = this.view.data('change-isprivate-url');
        this.ReloadDragAndDropGridUrl = this.view.data('reload-draganddropgrid-url');
        this.UpdateMsg = this.view.data('update-msg');
        this.SuccessOperationMsg = this.view.data('success-msg');
        this.ConfirmDeleteTitle = this.view.data('swal-title');
        this.ConfirmDeleteOverrideMsg = this.view.data('swal-msg');

        this.Init();
    }

    Init() {
        var self = this;


        // on s'abonne au click sur la checkbox isPrivate
        $("#IsPrivate", self.view).on("change",
            (e) => {

                Swal.fire({
                    title: self.ConfirmDeleteTitle,
                    text: self.ConfirmDeleteOverrideMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.ChangeIsPrivate(self, $("#IsPrivate", self.view).is(':checked'));
                    } else {
                        // on décoche/coche de nouveau la checkbox pour revert
                        $("#IsPrivate", self.view).prop('checked', !$("#IsPrivate", self.view).is(':checked'));
                    }
                })
            });

    }

    //Permet de refresh la grid des liaisons (pas celles du drag and drop)
    RefreshClientDistributeurGrid() {
        let self = this;

        var treeList = $("#ClientDistributeurGrid").data("kendoTreeList");

        treeList.dataSource.read();
        treeList.refresh();
        treeList.setDataSource(treeList.options.dataSource);
    }

    // Call Ajax pour changer la valeur de IsPrivate dui dsitributeur
    ChangeIsPrivate(self,value) {
        $.ajax({
            url: self.ChangeIsprivateUrl,
            data: {
                isPrivate: value
            },
            type: 'POST',
            success: function (data) {
                toastr.success(self.UpdateMsg + " " + self.SuccessOperationMsg);

                // Ici on va devoir rejouer les deux grilles de drag and drop
                $.ajax({
                    url: self.ReloadDragAndDropGridUrl,
                    type: 'POST',
                    success: function (data) {
                        // on charge l'html
                        $("#ClientDistributeurDragAndDrop-div", self.view).html(data);

                        //Puis on active le composant webpack via le data-mount
                        App.Kernel.bindComponents($('#ClientDistributeurDragAndDrop-div'));

                        // On refresh aussi la grille de clientDistributeur
                        self.RefreshClientDistributeurGrid();
                    }
                });
            }
        });


    }
}