/*
 *
 */

import toastr from 'toastr';
export default class AlertDeletion {

    constructor($view) {

        this.$view = $view;
        this.$deletion = this.$view.find('#Deletion');
        this.Init();
    }

    Init() {
        let self = this;
        if (this.$deletion.text().toLowerCase() == "false") {
            toastr.options = {
                timeOut: 0,
                extendedTimeOut: 0
            };
            toastr.error("La suppression n'est pas possible car cet élément doit être référencé ailleurs");
        }
        
        
    }

    callChangeCatalogues(self, idDis) {
        
    }
}