/*
 * Class permettant d'afficher le nom du fichier upload dans l'input
 */

export default class FileUploadEditor {

    constructor($view) {

        this.view = $view;

        this.Init();
    }

    Init() {
        var self = this;

        $(".custom-file-input", self.view).on("change", function () {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });

        // Pour l'instant cette partie ne sert pas
        $(".remove-file", self.view).on("click", function () {
            $(".custom-file-input", self.view).val("");
            $(".hiddenpath", self.view).val("");
            $(".custom-file-input", self.view).siblings(".custom-file-label").removeClass("selected").html("Aucun fichier selectionné");
        });
    }
}